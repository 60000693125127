import React from 'react';
import ReactDOM from 'react-dom';
import './scss/index.scss';
import '@fortawesome/fontawesome-free/js/all'
import App from './App';
import { Provider as ReduxProvider, connect } from 'react-redux';
import { createStore, bindActionCreators} from 'redux';
import AllReducers from './Redux/Reducers/Index';
import mapStateToProps from './Redux/Containers/MainContainer';

const store = createStore(
  AllReducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

function AppComponent(){
  return (
    <ReduxProvider store={store}>
      <App/>
    </ReduxProvider>
  )
}

ReactDOM.render(<AppComponent />, document.getElementById('root'));