import {
  SET_ROUTE
} from '../Types'

const initialState = {
  route: 'home'
};

export default (state = initialState, action) => {
  switch (action.type){
    case SET_ROUTE:
      return {
        ...state,
        route: action.payload
      }
  }
  return state
}
