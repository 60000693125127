import React, {useState} from 'react'
import { InView } from 'react-intersection-observer'
import { motion } from "framer-motion"
import { variants } from '../constants'

const Experience = [
  {
    role: "Full-stack developer",
    company: "BeTenerife",
    place: "Canary Islands, Spain",
    year: "2020 (Ongoing)",
    tasks: [
      "Designed and implemented the database models and structures.",
      "Designed and imiplemened a REST API with ExpressJs.",
      "Designed and implemented the user interface and user experience for a web appliication for their clients and a mobile application for the administration with React, React-Native and Redux.",
    ]
  },
  {
    role: "Front-end developer",
    company: "daytrip.travel",
    place: "Canary Islands, Spain",
    year: "2020 (Ongoing)",
    tasks: [
      "Helped with a prototype project to achieve a propper state management.",
      "Reviewed the use of React hooks in functional components.",
      "Added Redux to the app to improve how data was shared through the components.",
      "Implemented a guide on how to deploy an expo app to the app stores.",
      "Worked remotely on an agile environment and methodologies.",
      "Implemented features to improve the user experience in the app."
    ]
  },
  {
    role: "Freelance Consultant",
    company: "Ten Personalizado",
    place: "Canary Islands, Spain",
    year: "2020",
    tasks: [
      "Developed a custom navigation component that interfaced with shopify.",
      "Implemented modification in the liquid file structures.",
      "Helped set up the development for their e-commerce website."
    ]
  },
  {
    role: "Full-stack developer",
    company: "Booking Service / App (Personal project)",
    place: "Canary Islands, Spain",
    year: "2020",
    tasks: [
      "Designed and implemented the database models and structures in a MySql database.",
      "Implemented a REST API with ExpressJs.",
      "Designed and implemented the user interface and user experience for a client mobile application with React-Native and Redux.",
      "Designed and implemented the user interface and user experience for an admin web application with React and Redux.",
    ]
  },
  {
    role: "Mobile game developer",
    company: "Jumpy Tommy Game (Personal project)",
    place: "Canary Islands, Spain",
    year: "2020",
    tasks: [
      "Implemented two two-dimensional games base on React Game Engine."
    ]
  },
  {
    role: "Freelance junior full-stack developer",
    company: "Scirocco Rent a Boat",
    place: "Sardinia, Italy",
    year: "2019",
    tasks: [
      "Helped with the transition from a wordpress website to a custom web aplication.",
      "Designed and implemented the database models and structures in a MySql database.",
      "Implemented a multilingual web page to display dynamic information from the clients database.",
      "Implemented a booking system with React and Redux.",
      "Implemented a payment system with Strype and NodeJs.",
    ]
  },
  {
    role: "Freelance junior full-stack developer",
    company: "Cortona",
    place: "Weinheim, Germany",
    year: "2018 - 2019",
    tasks: [
      "Worked on file functionalities with PHP.",
      "Implemented a new design for the application.",
    ]
  },
  {
    role: "Junior full-stack developer",
    company: "Cortona",
    place: "Weinheim, Germany",
    year: "2018",
    tasks: [
      "Helped organising the file structure of the project.",
      "Researched the benefites of implementinig Redux for data managment.",
      "Implemented Redux for data managment.",
      "Designed and implemented the database models and structures in a MySql database.",
      "Added new functionalities to the application with React and Redux."
    ]
  }
]

function ThirdSection(){
  const [visible, setVisible] = useState(true)

  const drawExperience = (experience) => {
    return experience.map((el,indx) => {
      return (
        <div className="experienceItem" key={indx}>
          <div className="experienceInfo">
            <div className="role">
              {el.role}
            </div>
            <div className="company">
              {el.company}
            </div>
            <div className="place">
              {el.place}
            </div>
            <div className="year">
              {el.year}
            </div>
          </div>
          <div className="tasks">
            <ul>
              {el.tasks.map((task, indx2) => {
                return (
                  <li key={indx2}>{task}</li>
                )
              })}
            </ul>
          </div>
        </div>
      )
    })
  }

  return (
    // <InView 
    //   className="four"
    //   as="section"
    //   onChange={(inView, entry) => {
    //     setVisible(inView)
    //   }}
    //   threshold={[0.6]}
    //   triggerOnce={true}
    // >
    <section className="four2">
      <motion.div
        className="sectionFourMotion"
        initial="hidden"
        animate={visible == true ? 'visible' : 'hidden'}
        variants={variants}
      >
        <h1>Experience</h1>
        <div className="experienceContent">
          {drawExperience(Experience)}
        </div>
      </motion.div>
      {/* </InView> */}
    </section>
  )
}

export default ThirdSection
