import React, { useState } from 'react'
import FirstSection from './sections/FirstSection'
import SecondSection from './sections/SecondSection'
import ThirdSection from './sections/ThirdSection'
import ForthSection from './sections/ForthSection2'
import FifthSection from './sections/FifthSection'
import Nav from './components/Nav'
import SectionComp from './components/SecionComp'

function Spacer({height}){
  return <div style={{height: height}}></div>
}

function App() {

  return (
    <div className="App">
      <SectionComp 
        children={<FirstSection/>}
      />
      <SectionComp 
        children={<ThirdSection/>}
      />
      <SectionComp 
        children={<ForthSection/>}
      />
      {/* <SectionComp 
        children={<SecondSection/>}
      /> */}
      <SectionComp 
        children={<FifthSection/>}
      />
      <Spacer height={'50px'}/>
      <Nav />
    </div>
  )
}

export default App
