import React, {useState} from 'react'
import { InView } from 'react-intersection-observer'
import { motion, useMotionValue, useTransform } from "framer-motion"
import { variants } from '../constants'
import TextLoop from "react-text-loop"
import {ReactComponent as AppBuildersSvg} from '../img/SVG/appBuilders.svg'


function Title(){
  return (
    <div className="title">
      <h1>My name is</h1>
      <h1 className="name">Tomás Vich Cólogan</h1>
      <h1>and I like to</h1>
      <h1>
        <TextLoop interval={3000}>
          <p className="textLoopItem">solve problems 🧩</p>
          <p className="textLoopItem">build Apps 📱</p>
          <h2 className="textLoopItem">write code 🧑🏻‍💻</h2>
        </TextLoop>
      </h1>
      {/* <h1><p className="textLoopItem">Change this</p></h1> */}
    </div>
  )
}

function FirstSection(){

    return (
        <section className="one">
          <motion.div
            className="sectionOneMotion"
            initial="hidden"
            animate={'visible'}
            variants={variants}
          >
            <Title/>
            <div className="svgContainer">
              <AppBuildersSvg id="appBuildersSvg" className={`svgAppBuilders visible`}/>         
            </div>
          </motion.div>
        </section>
    )
}

export default FirstSection
