import React, {useState} from 'react'
import { InView } from 'react-intersection-observer'
import { motion } from "framer-motion"
import { variants } from '../constants'
import {ReactComponent as FeelingProudSvg} from '../img/SVG/feelingProud.svg'


function Text(){
  return (
    <div className="skills">
      <h1>Skills</h1>
      <ul className="brief">
        <li>Designing and implementing database models and structures.</li>
        <li>Designing and implementing REST APIs.</li>
        <li>Designing and implementing UX/UI.</li>
      </ul>
      <p>
        JavaScript, TypeScript, PHP
      </p>
      <p>
        React, React-Native, Redux frameworks
      </p>
      <p>
        ExpressJs REST API design and implementation
      </p>
      <p>
        Data modelling
      </p>
      <p>
        MySQL, Oracle, Neo4g
      </p>
      <p>
        Version-control systems (Git)
      </p>
      <p>
        Security (SSL)
      </p>
      <p>
        Virtualization (Virtualbox, VMWare, Docker)
      </p>
      <p>
        Network administration
      </p>
    </div>
  )
}

function ThirdSection(){
  const [visible, setVisible] = useState(true)

  return (
    // <InView 
    //   className="three"
    //   as="section"
    //   onChange={(inView, entry) => {
    //     setVisible(inView)
    //   }}
    //   threshold={[0.6]}
    //   triggerOnce={true}
    // >
    <section className="three">
      <motion.div
        className="sectionThreeMotion"
        initial="hidden"
        animate={visible == true ? 'visible' : 'hidden'}
        variants={variants}
      >
        <Text/>
        <div className="svgContainer">
          <FeelingProudSvg id="feelingProudSvg" className={`feelingProudSvg visible`}/>
        </div>
      </motion.div>
    {/* </InView> */}
    </section>
  )
}

export default ThirdSection
