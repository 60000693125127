import React, { useState } from 'react'

const NavItems = [
  // {title: "Menu 1", href: "http://tvcologan.com"},
  // {title: "Menu 2", href: "http://tvcologan.com"},
  // {title: "Menu 3", href: "http://tvcologan.com"},
  // {title: "Menu 4", href: "http://tvcologan.com"}
]

function navItems(items){
  return items.map((el, indx) => {
    return (<div key={indx}>{el.title}</div>)
  })
}

function NavBorder() {

  const [smallNavOpen, setSmallNavOpen] = React.useState(false)

  return (
    <div className="navigation">
      {/* <div className={`smallNav ${smallNavOpen === true ? 'diam' : ''}`}>
        <div className="logo">
          Tomás Vich Cólogan
        </div>
        <div id="pencet" onClick={() => setSmallNavOpen(!smallNavOpen)} className={`${smallNavOpen === true ? 'diam' : ''}`}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div> */}
      <div className={`smallNavMenuContainer ${smallNavOpen === true ? 'diam' : ''}`}>
        <div className="background"></div>
        <div className="dismissMenu" onClick={() => setSmallNavOpen(false)}></div>
        <div className="menu">
          <div className="menuLogo">
            Tomás Vich Cólogan
          </div>
          {navItems(NavItems)}
        </div>
      </div>
      <div className="NavBorderLeft NavBorder vertical"></div>
      <div className="NavBorderRight NavBorder vertical"></div>
      <div className="NavBorderTop NavBorder horizontal">
        <div className="logo">
          Tomás Vich Cólogan
        </div>
        <div className="nav">
          {navItems(NavItems)}
        </div>
      </div>
      <div className="NavBorderBottom NavBorder horizontal">
        <div className="socialIcon">
          <a href="mailto:tvcologan@gmail.com" alt="email">
            <i className="fas fa-at"></i>
          </a>
        </div>
        <div className="socialIcon">
          <a href="https://twitter.com/tvcologan">
            <i className="fab fa-twitter"></i>
          </a>
        </div>
        <div className="socialIcon">
          <a href="https://www.instagram.com/tomas_vich/">
            <i className="fab fa-instagram"></i>
          </a>
        </div>
        {/* <div className="socialIcon">
          <a href="">
            <i className="fab fa-mastodon"></i>
          </a>
        </div> */}
        <div className="socialIcon">
          <a href="https://keybase.io/tvcologan">
            <i className="fab fa-keybase"></i>
          </a>
        </div>
        <div className="socialIcon">
          <a href="https://github.com/tvcologan">
            <i className="fab fa-github"></i>
          </a>
        </div>
      </div>
    </div>
  )
}

export default NavBorder
