import React, {useState} from 'react'
import { InView } from 'react-intersection-observer'
import { motion } from "framer-motion"
import { variants } from '../constants'


function FifthSection(){
  const [visible, setVisible] = useState(true)

  return (
    // <InView 
    //   className="five"
    //   as="section"
    //   onChange={(inView, entry) => {
    //     setVisible(inView)
    //   }}
    //   threshold={[0.6]}
    //   triggerOnce={true}
    // >
    <section className="five">
      <motion.div
        className="sectionFiveMotion"
        initial="hidden"
        animate={visible == true ? 'visible' : 'hidden'}
        variants={variants}
      >
          <h1>Get in touch</h1>
          <div >
            <a href="mailto:tvcologan@gmail.com">
              <div className="mailBtn">
                Email me!
              </div>
            </a>
          </div>
          <div className="socialIcons">
            <div className="socialIcon">
              <a href="https://twitter.com/tvcologan">
                <i className="fab fa-twitter"></i>
              </a>
            </div>
            <div className="socialIcon">
              <a href="https://www.instagram.com/tomas_vich/">
                <i className="fab fa-instagram"></i>
              </a>
            </div>
            {/* <div className="socialIcon">
              <a href="">
                <i className="fab fa-mastodon"></i>
              </a>
            </div> */}
            <div className="socialIcon">
              <a href="https://keybase.io/tvcologan">
                <i className="fab fa-keybase"></i>
              </a>
            </div>
            <div className="socialIcon">
              <a href="https://github.com/tvcologan">
                <i className="fab fa-github"></i>
              </a>
            </div>
          </div>
      </motion.div>
      {/* </InView> */}
    </section>
  )
}

export default FifthSection
